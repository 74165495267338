import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MenuChangeEvent } from '../api/menuchangeevent';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    constructor() {}

    private menuSource = new Subject<MenuChangeEvent>();
    private resetSource = new Subject();

    menuSource$ = this.menuSource.asObservable();
    resetSource$ = this.resetSource.asObservable();

    menuModel = [
        {
            label: 'Home',
            items: [
                { label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/'] }
            ]
        },
        {
            label: 'Customer Management',
            items: [
                { label: 'Account Search', icon: 'pi pi-fw pi-search', routerLink: ['/accounts/search'] },
                { label: 'RTBF', icon: 'pi pi-fw pi-times', routerLink: ['/rtbf'] },
            ]
        },
        {
            label: 'Database',
            items: [
                { label: 'Reports', icon: 'pi pi-fw pi-table', routerLink: ['/reports'] }
            ]
        },
        {
            label: 'Pulse',
            items: [
                { label: 'Logs', icon: 'pi pi-fw pi-file', routerLink: ['/pulse/messages'] },
                { label: 'Partners', icon: 'pi pi-fw pi-users', routerLink: ['/pulse/partners'] },
                { label: 'Errors', icon: 'pi pi-fw pi-times-circle', routerLink: ['/pulse/errors'] },
            ]
        }
    ];

    onMenuStateChange(event: MenuChangeEvent) {
        this.menuSource.next(event);
    }
}
