import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { EMPTY, Observable, catchError, from, mergeMap, throwError } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { SilentRequest, AuthenticationResult, InteractionRequiredAuthError, RedirectRequest } from '@azure/msal-browser';
import { MessageService } from 'primeng/api';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: MsalService, private messageService: MessageService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const account = this.authService.instance.getActiveAccount();
    if (!account) {
      // Handle the case where there is no active account
      this.login();
      return EMPTY;
    }

    const requestObj: SilentRequest = {
      account,
      scopes: ['api://b843d78c-a646-42c7-9c7b-7ad8828d160e/API.Access']
    };

    return from(this.authService.acquireTokenSilent(requestObj)).pipe(
      mergeMap((response: AuthenticationResult) => {
        const authToken = response.accessToken;
        const modifiedRequest = request.clone({
          setHeaders: {
            Authorization: `Bearer ${authToken}`
          }
        });
        return next.handle(modifiedRequest);
      }),
      catchError((err: HttpErrorResponse) => {

        if (err instanceof InteractionRequiredAuthError) {
          // If interaction required, redirect to login
          const redirectRequest: RedirectRequest = {
            ...requestObj,
            redirectUri: window.location.origin,
            authority: 'https://login.microsoftonline.com/6fe48ad2-8b7b-483a-8f25-28fe1a030a74'
          };
          this.authService.acquireTokenRedirect(redirectRequest);
          return EMPTY; // Return an empty observable so that the HTTP request does not proceed
        }
        // Handle token acquisition errors
        if (err.status === 401) {
          this.messageService.add({ severity: 'error', summary: 'Auth Error', detail: `Unable to authorize api request ${request.url} for ${account.username}.`, life: 30000 })
        }
        console.log(err);
        return throwError(() => err);

      })
    );
  }

  private login(): void {
    this.authService.loginRedirect();
  }
}